<template>
  <div class="contact-us">
    <div class="Help-box">
      <div class="Help-content">
        <div class="contact-us-header">
          <h3 v-if="nowElectron">{{getString(Strings.Setting_Up)}}</h3>
          <!-- 意见反馈 -->
          <h3>{{getString(Strings.Contact_Us_Message_Board)}}</h3>
        </div>
        <div class="Help-seach">
          <div class="hblang">
            <div v-if="nowElectron" class="contact hb">
              <ul>
                <!-- <li class="con-li">
                  <p class="conimg con-emailimg">
                    <img
                      src="../../assets/img/user/Iitem0selected/phone.svg"
                      alt=""
                    />
                  </p>
                  <p class="wz">{{getString(Strings.Telephone)}}:&nbsp;</p>
                  <p class="conzi">
                    <a class="number" href="tel:400-139-2904">400-139-2904</a>
                  </p>
                </li> -->
                <li class="con-li">
                  <p class="conimg con-emailimg">
                    <img
                      src="../../assets/img/user/Iitem0selected/emails947.svg"
                      alt=""
                    />
                  </p>
                  <p class="wz">{{getString(Strings.Contact_Us_Email)}}:&nbsp;</p>
                  <p class="conzi">
                    <span>yushu_mind@sina.com</span>  <span>yushu_mind_support@yeah.net</span>
                  </p>
                </li>
                <li class="con-li">
                  <p class="conimg">
                    <img
                      src="../../assets/img/user/Iitem0selected/weibos948.svg"
                      alt=""
                    />
                  </p>
                  <p class="wz">{{getString(Strings.Microblog)}}:&nbsp;</p>
                  <p class="conzi"><a href="https://weibo.com/u/7264879276" target="_blank" style="text-decoration:underline;">https://weibo.com/u/7264879276</a></p>
                </li>
               <!-- <li class="con-li">
                  <p class="conimg">
                    <img
                      src="../../assets/img/user/Iitem0selected/adress.svg"
                      alt=""
                    />
                  </p>
                  <p class="wz">{{getString(Strings.Address)}}:&nbsp;</p>
                  <p class="conzi">
                      {{getString(Strings.Company_Address)}}
                  </p>
                </li> -->
                <div class="con-div">
                  <div class="condimg">
                    <p class="cond-img">
                     <img src="../../assets/img/user/Iitem0selected/weixinD.png" alt="">
                    </p>
                    <p class="offi-cial conzi">{{getString(Strings.WeChat)}}</p>
                  </div>
                   <div class="condimg">
                    <p class="cond-img">
                     <img src="../../assets/img/user/Iitem0selected/bjnews.png" alt="">
                    </p>
                    <p class="offi-cial conzi">{{getString(Strings.Contact_Us_No_Public)}}</p>
                  </div>
                </div>
              </ul>
            </div>
            <div class="box-line"></div>
            <div class="message-board hb">
              <div>
                <a-form :form="sendLeaveNote" @submit="handleSendLeaveNote">
                  <div class="shuang em">
                    <a-input-group size="large">
                      <a-col :span="12">
                        <a-form-item>
                          <a-input
                            ref="userNameInput"
                            :placeholder="getString(Strings.Contact_Us_Username)"
                            class="sleft"
                            v-decorator="[
                              'name',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: getString(Strings.Input_Username_isNull),
                                  },
                                ],
                              },
                            ]"
                            @input="formValidation('name')"
                          >
                            <!-- <span
                              class="iconfont2 iconfont-dark-color"
                              slot="prefix"
                              >&#xe605;</span
                            > -->
                          </a-input>
                        </a-form-item>
                      </a-col>
                      <a-col :span="12" class="sright">
                        <a-form-item>
                          <a-input
                            ref="userEmailInput"
                            :placeholder="getString(Strings.Contact_Us_Email)"
                            v-decorator="[
                              'email',
                              {
                                rules: [
                                  {
                                    type: 'email',
                                    required: true,
                                    message: getString(Strings.Input_Email_isNull),
                                  },
                                ],
                              },
                            ]"
                            @input="formValidation('email')"
                          >
                            <!-- <span
                              class="iconfont2 iconfont-dark-color"
                              slot="prefix"
                              >&#xe62e;</span
                            > -->
                          </a-input>
                        </a-form-item>
                      </a-col>
                    </a-input-group>
                  </div>
                  <div class="guestbook em">
                    <!-- <span class="iconfont2 iconfont-dark-color" slot="prefix"
                      >&#xe657;</span
                    > -->
                    <a-form-item>
                      <a-textarea
                        :placeholder="getString(Strings.Contact_Us_Voicemail)+'...'"
                        :rows="12"
                        style="resize: none;text-indent:2px;background: #FBFBFB;"
                        v-decorator="[
                          'send',
                          {
                            rules: [
                              {
                                required: true,
                                message: getString(Strings.Input_Voicemail_isNull),
                              },
                            ],
                          },
                        ]"
                        @input="formValidation('send')"
                      />
                    </a-form-item>
                  </div>

                  <a-form-item>
                    <div class="send-button em" :class="CanBeSent?'can-sent':'no-sent'">
                      <a-button ref="submitButton" html-type="submit">{{getString(Strings.Global_Ok)}}</a-button>
                    </div>
                  </a-form-item>
                </a-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import unifildStyle from "../../assets/css/help/unifildStyle.less";

import { postBaseSendLeaveNote } from "../../common/netWork/base_api";
import PageCheckBox from "../common/PageCheckBox.vue";
import Strings from "../../common/lang/strings";
import getString from "../../common/lang/language";
export default {
  components: {
    PageCheckBox,
  },
  data() {
    return {
      sendLeaveNote: this.$form.createForm(this),
      Strings,
      nowElectron: false, //当前是否在electron环境中
      CanBeSent:false,
      inputValidation:{
        name:false,
        email:false,
        send:false
      }
    };
  },
  created() {
    this.nowElectron = this.$tools.isElectron()
  },
  mounted() {},
  methods: {
    getString(i){
     return getString(i)
    },
    //提交内容
    handleSendLeaveNote(e) {
      e.preventDefault();
      this.sendLeaveNote.validateFields(
        ["name", "email", "send"],
        { first: true },
        (err, values) => {
          if (!err) {
            postBaseSendLeaveNote(
              {
                username: values.name,
                email: values.email,
                content: values.send,
              },
              (res) => {
                this.$message.success(getString(Strings.Message_Tips_Submit_Success));
                this.sendLeaveNote.setFieldsValue({
                  name: "",
                  email: "",
                  send: "",
                });
              },
              (error) => {
                this.$message.success(error.desc);
              }
            );
          }
        }
      );
    },
    //表单验证
    formValidation(options){
      this.sendLeaveNote.validateFields(
        [options],
        (err, values) => {
          this.inputValidation[options] = !err;
          for (const key in this.inputValidation) {
            if (Object.hasOwnProperty.call(this.inputValidation, key)) {
              const element = this.inputValidation[key];
              this.CanBeSent = element;
              if (!element) {
                return
              }
            }
          }
        }
      );
    }
  },
  watch: {

  },
  computed: {},
};
</script>

<style scoped lang="less">
ul li {
  list-style: none;
}
.contact-us{
  background: #FBFBFB;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0px;
  padding: 20px;
} 
.contact-us-header{
  display: flex;
}
.contact-us-header h3{
    width: 50%;
    font-size: 16px;
    color: #666666;
    margin-bottom: 12px;
    float: left;
}

.Help-bottom {
  height: 78px;
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 8px;
}
.Help-bottom .hbb-ul {
  padding: 1.5em 3em;
  margin-bottom: unset;
}

.Help-bottom .hb-li {
  display: flex;
}
.Help-bottom .hb-li img {
  width: 26px;
}
.Help-seach {
  background: #ffffff;
  padding: 30px 20px;
  // box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
  // border-radius: 8px;
  position: relative;

}
.Help-seach .hblang {
  width: 100%;
  height: 100%;
  /* padding: 2em 5em; */
  display: flex;
}
.Help-seach .hblang .hb {
  width: 50%;
}
.Help-seach .hblang .message-board {
  position: relative;
  left: .4%;
}
.Help-seach .hblang .box-line{
  width: 20px;
  height: 546px;
  background: #FBFBFB;
  position: absolute;
  top: 0;
  left: 48%;
}
.Help-seach .hblang p {
  margin-bottom: unset;
}
.hblang h3 {
  font-size: 18px;
  color: #666666;
  margin-bottom: 1.5em;
}
.contact ul {
  padding-left: unset;
  width: 95%;
}
.contact h2 {
  color: #666;
}
.contact .con-li {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}
.contact .conimg {
  width: 4%;
}
.contact .wz {
   font-size: 16px;
   color: #666;
   margin-left: 6px;
}
.contact .conzi {
  padding-left: 10px;
  line-height: 35px;
  font-size: 14px;
  font-family: Noto Sans SC;
  font-weight: 400;
  color: #666;
  opacity: 1;user-select: text;
}
.contact .conzi span:first-child{
  margin-right: 10px;
}
.contact .conzi a{
  color: #666;
}
.contact .conzi .number{
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FF765E;
  text-decoration: underline;
  margin-left: unset;
}
.contact .con-div {
  display: flex;
}
.contact .con-div .condimg {
  margin-left: 11%;
  /* display: flex; */
}
.contact .con-div .condimg img {
  width: 80px;
  display: block;
  margin: 0 auto;
}
.contact .con-div .condimg .offi-cial {
  line-height: unset;
  text-align: center;
  padding: 0;
}
.em {
  margin-bottom: 10px;
}
.shuang .sright {
  float: right;
}

.shuang .sleft input {
  height: 52px;
  border: 1px solid #d9d9d9;
  opacity: 1;
  border-radius: 4px;
}
.guestbook span {
  z-index: 5;
  padding-left: 20px;
  padding-top: 5px;
  position: absolute;
}
.ant-input:placeholder-shown {
  z-index: 2;
}
.send-button button {
  width: 100%;
  height: 40px;
  // background: #fd492b;
  border: 1px solid #EEEEEE;
  font-size: 16px;
  color: #333333;
  border-radius: 4px;
  outline: none;
}
.can-sent button {
  background: #fd492b;
  color: #fff;
}
.no-sent button {
  background: #f1f1f1;
  color: #333333;
}

@font-face {
  font-family: "iconfont2";
  src: url("../../assets/font-icon/font_6d57ta7yli4/iconfont.eot");
  src: url("../../assets/font-icon/font_6d57ta7yli4/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font_6d57ta7yli4/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/font_6d57ta7yli4/iconfont.woff") format("woff"),
    url("../../assets/font-icon/font_6d57ta7yli4/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font_6d57ta7yli4/iconfont.svg#iconfont")
      format("svg");
}

.iconfont2 {
  font-family: "iconfont2" !important;
  font-size: 16px;
  /*font-weight: bold;*/
  font-style: normal;
  color: #e5e5e5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.iconfont-dark-color {
  color: #d9d9d9;
}
/deep/ .ant-input-group-lg .ant-input, .ant-input-group-lg > .ant-input-group-addon {
    height: 36px;
    padding: 6px 11px;
    font-size: 14px;
    background: #FBFBFB;
}
</style>

